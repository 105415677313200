import { referenceFrames } from '@newStore/referenceFrames/referenceFrames';
import { loadReferenceFrame } from '@newStore/referenceFrames/referenceFramesState';
import { loadWebConfigurations } from '@newStore/documentApi/documentApiState';
import { RequiredType, RootNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { ContentType } from '@generalTypes/apiTypes';
import { publishComponent } from '@nodeTypeConfig/helpers/genericComponents';
import { authorsComponent } from '@nodeTypeConfig/helpers/genericComponents';
import { settings } from '../../settings';
import constants from '../../../reduxLoop/constants/constants';
import { accessRights } from '../../../reduxLoop/helpers/accessRights';
import { proDefaultBuildingBlocks, proThemeAudienceTab } from './proGenericConfigProps';
// Generic Pro Theme in the list screen or onLoading before it has the full document context and becomes something more specific
// Also WEBPAGE2 with invalid WebConfig will not match a specific Pro Theme and will be a WEBPAGE2
export const WEBPAGE2: RootNodeConfig = {
  information: {
    definiteArticle: true,
    single: 'PRO.-thema',
    plural: "PRO.-thema's",
    icon: require('../../../../img/icons/webpage.svg'),
    category: 'PRO',
  },
  onLoadActions: [
    loadWebConfigurations(),
    loadReferenceFrame({ referenceFrame: referenceFrames.pro }),
  ],
  createDefaults: {
    accessRights: accessRights.publiek.namedsets,
    descendantsAccessRights: accessRights.publiek.namedsets,
  },
  buildingBlocks: proDefaultBuildingBlocks,
  tocTypes: [NodeType.PRO_THEME_SECTION, NodeType.FAQ_GROUP],
  edit: [
    {
      component: 'title',
      property: 'title',
      reactComponent: 'AsideTitle',
      label: 'Titel',
      required: RequiredType.ERROR,
    },
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      property: 'description',
      label: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
    },
    {
      component: 'image',
      width: '6',
      label: 'Tegelfoto',
      options: {
        type: 'THUMBNAIL',
        crop: true,
        fields: ['alt'],
      },
    },
    {
      ...authorsComponent,
      label: 'Themaverantwoordelijke',
    },
    publishComponent,
    {
      component: 'themes',
      label: "Thema's",
      options: {
        referenceFrameKey: constants.dienstverleningKovKey,
        types: [ContentType.THEME, ContentType.CURRICULUM_THEME, ContentType.CURRICULUM_CLUSTER],
        highlightOldCurrItems: true,
      },
    },
    {
      component: 'curriculumSelector',
      reactComponent: 'AsideCurriculumSelector',
      property: 'themes',
      label: "Leerplanthema's",
    },
    {
      component: 'contacts',
      property: 'contacts',
      label: 'Contactpersonen',
      reactComponent: 'AsidePersonPicker',
      freeText: false,
      width: '12',
      options: {
        queryParams: constants.defaultAuthorsParameters,
      },
    },
    {
      component: 'menuItemReference',
      label: 'Navigeert naar',
      options: {
        filter: {
          typeIn: 'STRUCTURED_DOCUMENT,SECTION',
          rootTag: [NodeType.WEBPAGE2],
          $$expandPathToRoot: true,
          orderBy: '$$meta.modified',
          descending: true,
        },
      },
    },
    {
      component: 'attachmentsGroup',
      label: 'Bijlagen',
      options: { global: true },
    },
    {
      component: 'linkGroup',
      reactComponent: 'AsideLinks',
      width: '12',
      label: 'Links',
      options: {
        type: 'group',
      },
    },
  ],
  audienceTab: proThemeAudienceTab,
  addAccessRightsToAudienceTab: true,
  isCreatable: true,
  isSearchable: true,
  isImportable: true,
  isCloneable: true,
  websiteEdition: [
    {
      domain: settings.domains.www,
    },
    {
      domain: settings.domains.pro,
    },
  ],
  allowSuggestions: true,
  globalAttachmentsGroupContainer: true,
};
