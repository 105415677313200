import { EditAsideExpiryDatePicker } from '@nodeTypeConfig/configTypes';
import { patchNodeAction } from '@store/actions/documentActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import 'moment/locale/nl-be';
import { any } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useInitialValue } from '../../../hooks/UseInitialValue';
import DatePickerElement from './DatePicker';

const AsideDatePicker: React.FC<{
  config: EditAsideExpiryDatePicker;
}> = ({ config }) => {
  const dispatch = useDispatch();

  const editHref = useEditHref();

  const currentValue = useInitialValue(config.property);

  const currentDate = currentValue ? new Date(currentValue) : null;

  const change = (value: Date | null) => {
    dispatch(
      patchNodeAction(
        getResourceKey(editHref),
        { [config.property]: value ? value.toISOString() : undefined },
        true,
        null,
        false
      )
    );
  };

  return <DatePickerElement config={config} value={currentDate} onChange={change} />;
};

AsideDatePicker.displayName = 'AsideDatePicker';

export default AsideDatePicker;

AsideDatePicker.propTypes = {
  config: any,
};
