import asideCurrent from './asideCurrent';
import asideThemes from './asideThemes';
import asideSubjects from './asideSubjects';
import asideAccessRights from './asideAccessRights';
import asideMenuItemReference from './asideMenuItemReference';
import asideSelect from './asideSelect';
import asideNamedSet from './asideNamedSet';
import asideCoverage from './asideCoverage';
import asideImage from './asideImage';
import asideVideo from './asideVideo';
import asideVideoGroup from './asideVideoGroup';
import asideMusic from './asideMusic';
import asideDownloadTag from './asideDownloadTag';
import linkModal from './modals/linkModal';
import videoGroupModal from './modals/videoGroupModal';
import sourceModal from './modals/sourceModal';
import asideReferenceGroup from './asideReferenceGroup';
import referenceSelectionModal from './modals/referenceSelectionModal';
import asideConcordanties from './asideConcordanties';
import concordantieModal from './modals/concordantieModal';
import asideAgeRange from './zill/asideAgeRange';
import asideLeerlijnReference from './zill/asideLeerlijnReference';
import asideDevelopmentPhase from './zill/asideDevelopmentPhase';
import asideGoalsByZillIllustrations from './zill/asideGoalsByZillIllustrations';
import asideZillIllustrations from './practicalExample/asideZillIllustrations';
import zillIllustrationModal from './modals/zillIllustrationModal';
import asidePracticalExampleReferences from './practicalExample/asidePracticalExampleReferences';
import documentSelectorModal from './modals/documentSelectorModal';
import asideWebFacets from './facets/metadataContainer';
import proWebsiteFacetShow from './facets/facetShow';
import proWebsiteFacetShowList from './facets/facetShowList';
import proWebsiteFacet from './facets/facet';
import proWebsiteFacets from './facets/facets';
import proWebsiteFacetModalForm from './facets/facetModalForm';
import proWebsiteReferenceFramePicker from './facets/referenceFramePicker';
import proWebsiteReferenceFramePickers from './facets/referenceFramePickers';
import proWebsiteAgeRange from './facets/ageRange';
import proWebsiteZillGoals from './facets/zillGoals';
import llinkidReferenceModal from './llinkid/referenceModal';
import llinkidPreviousVersionModal from './modals/llinkidPreviousVersionModal';
import llinkidGoalRelationsModal from './modals/goalRelationsModal';
import llinkidGoalOdetEndTermReferencesModal from './modals/goalOdetEndTermReferencesModal';
import asideLlinkidFoundationalSuggestions from './llinkid/foundationalSuggestions';
import asideAttachmentsGroup from './asideAttachmentsGroup';
import attachmentModal from './modals/attachmentModal';
import asideAttachmentUpload from './asideAttachmentsUpload';
import asideImageGroup from './asideImageGroup';
import imageSelectionModal from './modals/imageSelectionModal';
import asideDownloadDocument from './asideDownloadDocument';

export default angular
  .module('redactieAsideComponents', [])
  .component('asideThemes', asideThemes)
  .component('asideSubjects', asideSubjects)
  .component('asideAccessRights', asideAccessRights)
  .component('asideMenuItemReference', asideMenuItemReference)
  .component('asideSelect', asideSelect)
  .component('asideCurrent', asideCurrent)
  .component('asideNamedSet', asideNamedSet)
  .component('asideCoverage', asideCoverage)
  .component('asideImage', asideImage)
  .component('asideMusic', asideMusic)
  .component('asideVideo', asideVideo)
  .component('asideVideoGroup', asideVideoGroup)
  .component('asideDownloadTag', asideDownloadTag)
  .component('linkModal', linkModal)
  .component('videoGroupModal', videoGroupModal)
  .component('sourceModal', sourceModal)
  .component('asideReferenceGroup', asideReferenceGroup)
  .component('referenceSelectionModal', referenceSelectionModal)
  .component('asideConcordanties', asideConcordanties)
  .component('concordantieModal', concordantieModal)
  .component('asideAgeRange', asideAgeRange)
  .component('asideLeerlijnReference', asideLeerlijnReference)
  .component('asideDevelopmentPhase', asideDevelopmentPhase)
  .component('asideGoalsByZillIllustration', asideGoalsByZillIllustrations)
  .component('asideZillIllustrations', asideZillIllustrations)
  .component('zillIllustrationModal', zillIllustrationModal)
  .component('asidePracticalExampleReferences', asidePracticalExampleReferences)
  .component('documentSelectorModal', documentSelectorModal)
  .component('asideWebFacets', asideWebFacets)
  .component('proWebsiteFacetShow', proWebsiteFacetShow)
  .component('proWebsiteFacetShowList', proWebsiteFacetShowList)
  .component('proWebsiteFacet', proWebsiteFacet)
  .component('proWebsiteFacets', proWebsiteFacets)
  .component('proWebsiteFacetModalForm', proWebsiteFacetModalForm)
  .component('proWebsiteReferenceFramePicker', proWebsiteReferenceFramePicker)
  .component('proWebsiteReferenceFramePickers', proWebsiteReferenceFramePickers)
  .component('proWebsiteAgeRange', proWebsiteAgeRange)
  .component('proWebsiteZillGoals', proWebsiteZillGoals)
  .component('llinkidReferenceModal', llinkidReferenceModal)
  .component('llinkidPreviousVersionModal', llinkidPreviousVersionModal)
  .component('llinkidGoalRelationsModal', llinkidGoalRelationsModal)
  .component('llinkidGoalOdetEndTermReferencesModal', llinkidGoalOdetEndTermReferencesModal)
  .component('asideLlinkidFoundationalSuggestions', asideLlinkidFoundationalSuggestions)
  .component('asideAttachmentsGroup', asideAttachmentsGroup)
  .component('attachmentModal', attachmentModal)
  .component('asideAttachmentUpload', asideAttachmentUpload)
  .component('asideImageGroup', asideImageGroup)
  .component('imageSelectionModal', imageSelectionModal)
  .component('asideDownloadDocument', asideDownloadDocument);
